import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem', // Adjust this value to ensure content is not under the navigation bar
    paddingBottom: '2rem',
    minHeight: '100vh',
    backgroundColor: '#000', // Set background color to black
    color: '#fff', // Set text color to white
});

const Section = styled('div')({
    marginBottom: '1.5rem',
});

const Footer = styled('footer')({
    backgroundColor: '#000',
    padding: '20px 0',
});

const FooterContainer = styled('div')({
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',

    '@media (min-width: 768px)': {
        gap: '15px',
    },
});

const FooterLink = styled('a')({
    color: '#12c099',
    textDecoration: 'none',
    fontSize: '0.8em',

    '&:hover': {
        color: '#0f9f85',
    },

    '@media (min-width: 768px)': {
        fontSize: '0.9em',
    },
});

const Privacy = () => {
    return (
        <>
            <StyledContainer maxWidth="lg">
                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 1: Information We Collect
                    </Typography>
                    <Typography variant="body1">
                        Mars Reel Media Corp collects various types of information in connection with the services we provide, including personal data such as your name, email address, phone number, and payment information.
                    </Typography>
                </Section>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 2: How We Use Your Information
                    </Typography>
                    <Typography variant="body1">
                        We use the information we collect to provide, maintain, and improve our services, including to process transactions, communicate with you, and to deliver targeted advertisements and services.
                    </Typography>
                </Section>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 3: Sharing Your Information
                    </Typography>
                    <Typography variant="body1">
                        We may share your information with third parties for purposes such as processing payments, providing customer support, and for marketing and advertising purposes. We ensure these third parties comply with strict data protection regulations.
                    </Typography>
                </Section>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 4: Data Security
                    </Typography>
                    <Typography variant="body1">
                        We implement various security measures to protect your personal data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.
                    </Typography>
                </Section>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 5: Your Data Protection Rights
                    </Typography>
                    <Typography variant="body1">
                        You have the right to request access to, correction of, or deletion of your personal data. You can also object to the processing of your personal data in certain circumstances. To exercise these rights, please contact us at privacy@marsreel.com.
                    </Typography>
                </Section>
                {/* Add more sections as needed */}
            </StyledContainer>
            <Footer>
                <FooterContainer>
                    <FooterLink href="/faq">FAQ</FooterLink>
                    <FooterLink href="/company-policy">Company Policy</FooterLink>
                    <FooterLink href="/privacy">Privacy Policy</FooterLink>
                    <FooterLink href="/terms-of-use">Terms of Use</FooterLink>
                    <FooterLink href="/help-center">Help Center</FooterLink>
                </FooterContainer>
            </Footer>
        </>
    );
};

export default Privacy;
