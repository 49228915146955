import React, { useState } from 'react';
import { Typography, Container, TextField, Button, Grid, Box, List, ListItem, ListItemText, Collapse, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Link, Pagination } from '@mui/material';
import { styled } from '@mui/system';
import { ExpandLess, ExpandMore, FileCopy as FileCopyIcon, RemoveCircleOutline as RemoveCircleOutlineIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';

const Root = styled('div')({
    position: 'relative',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1c1c1c',
    padding: '2rem',
    overflowX: 'hidden',
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '1rem',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
});

const ReferralStage = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#303030',
    padding: '1rem',
    borderRadius: '8px',
    marginBottom: '0.5rem',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

const Footer = styled('footer')(({ theme }) => ({
    position: 'relative',
    marginTop: '2rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',
    [theme.breakpoints.up('md')]: {
        gap: '15px',
    },
}));

const FooterLink = styled('a')(({ theme }) => ({
    color: '#12c099',
    textDecoration: 'none',
    fontSize: '0.8em',
    '&:hover': {
        color: '#0f9f85',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '0.9em',
    },
}));

const dummyReferrals = Array.from({ length: 20 }, (_, index) => ({
    name: `Referral ${index + 1}`,
    email: `referral${index + 1}@example.com`,
    phone: `123-456-78${index.toString().padStart(2, '0')}`,
    status: 'pending',
    stages: [
        { description: 'Invitation Sent', date: '2024-07-01' },
        { description: 'Invitation Accepted', date: '2024-07-02' },
    ],
}));

const dummyPayouts = [
    {
        amount: 50,
        date: '2024-07-10',
        reference: 'REF12345',
    },
    {
        amount: 75,
        date: '2024-06-20',
        reference: 'REF67890',
    },
];

const AffiliateDashboard = () => {
    const [firstName, setFirstName] = useState('John'); // Example user name
    const [referralCode, setReferralCode] = useState('ABC123XYZ'); // Example referral code
    const [invitees, setInvitees] = useState([{ contact: '' }]);
    const [referrals, setReferrals] = useState(dummyReferrals); // Use dummy data for referrals
    const [expanded, setExpanded] = useState({});
    const [personalInfo, setPersonalInfo] = useState({
        firstName: 'John',
        lastName: 'Doe',
        email: 'john.doe@example.com',
        phone: '123-456-7890',
        street: '123 Main St',
        city: 'Anytown',
        state: 'CA',
        zipCode: '12345',
    });
    const [earnings, setEarnings] = useState(100); // Example earnings
    const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
    const [payouts, setPayouts] = useState(dummyPayouts); // Use dummy data for payouts

    const [currentPage, setCurrentPage] = useState(1);
    const referralsPerPage = 5;
    const indexOfLastReferral = currentPage * referralsPerPage;
    const indexOfFirstReferral = indexOfLastReferral - referralsPerPage;
    const currentReferrals = referrals.slice(indexOfFirstReferral, indexOfLastReferral);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleInviteChange = (index, value) => {
        const updatedInvitees = [...invitees];
        updatedInvitees[index].contact = value;
        setInvitees(updatedInvitees);
    };

    const handleAddInvitee = () => {
        setInvitees([...invitees, { contact: '' }]);
    };

    const handleRemoveInvitee = (index) => {
        const updatedInvitees = invitees.filter((_, i) => i !== index);
        setInvitees(updatedInvitees);
    };

    const handleInvite = () => {
        // Handle sending invites
        alert(`Invitations sent to ${invitees.map(friend => friend.contact).join(', ')}`);
    };

    const handleExpandClick = (index) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [index]: !prevExpanded[index],
        }));
    };

    const handleChange = (e) => {
        setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
    };

    const handleSavePersonalInfo = () => {
        // Save updated personal info
        alert('Personal info saved');
    };

    const handleCopyReferralCode = () => {
        navigator.clipboard.writeText(referralCode).then(() => {
            alert('Referral code copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy referral code: ', err);
        });
    };

    const handleWithdrawEarnings = () => {
        setWithdrawDialogOpen(true);
    };

    const handleCloseWithdrawDialog = () => {
        setWithdrawDialogOpen(false);
    };

    const handleConfirmWithdraw = () => {
        // Handle withdrawal logic
        alert('Withdrawal request submitted');
        setWithdrawDialogOpen(false);
    };

    const renderTrackingUI = (stages) => (
        <Box display="flex" alignItems="center" marginTop="1rem" justifyContent="flex-start" flexWrap="wrap">
            {stages.map((stage, index) => (
                <Box key={index} display="flex" alignItems="center" marginRight="1rem">
                    <CheckCircleIcon style={{ color: '#12c099', marginRight: '0.5rem' }} />
                    <Typography variant="body2" style={{ color: '#fff' }}>{stage.description} <br />({stage.date})</Typography>
                </Box>
            ))}
        </Box>
    );

    return (
        <Root>
            <Container maxWidth="lg" style={{ marginTop: '6rem' }}>
                <Typography variant="h4" gutterBottom>
                    Hello, {firstName}
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Affiliate Dashboard
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Welcome to the Affiliate Dashboard. Here you can manage your referrals and track your earnings.
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box bgcolor="#12c099" color="#fff" p={3} borderRadius={2} boxShadow={3} textAlign="center">
                            <Typography variant="h6">Your Earnings</Typography>
                            <Typography variant="h4" mt={1}>${earnings}</Typography>
                            <ActionButton onClick={handleWithdrawEarnings} style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Withdraw Earnings</ActionButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box bgcolor="#202020" color="#fff" p={3} borderRadius={2} boxShadow={3}>
                            <Typography variant="h6">Your Referral Code</Typography>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    value={referralCode}
                                    fullWidth
                                    variant="filled"
                                    InputProps={{
                                        readOnly: true,
                                        style: { color: '#fff' },
                                    }}
                                />
                                <Tooltip title="Copy referral code">
                                    <IconButton onClick={handleCopyReferralCode} style={{ color: '#12c099', marginLeft: '0.5rem' }}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box bgcolor="#202020" color="#fff" p={3} borderRadius={2} boxShadow={3}>
                            <Typography variant="h6">Invite Friends</Typography>
                            {invitees.map((invitee, index) => (
                                <Box key={index} display="flex" alignItems="center" mt={1}>
                                    <TextField
                                        label="Enter email or phone number"
                                        value={invitee.contact}
                                        onChange={(e) => handleInviteChange(index, e.target.value)}
                                        fullWidth
                                        variant="filled"
                                        InputLabelProps={{
                                            style: { color: 'rgba(255, 255, 255, 0.6)' },
                                        }}
                                        InputProps={{
                                            style: { color: '#fff' },
                                        }}
                                    />
                                    {invitees.length > 1 && (
                                        <IconButton onClick={() => handleRemoveInvitee(index)} style={{ marginLeft: '0.5rem', color: '#ff1744' }}>
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                            <Button onClick={handleAddInvitee} style={{ marginTop: '1rem', color: '#12c099' }}>
                                Add Another Friend
                            </Button>
                            <ActionButton onClick={handleInvite}>Send Invites</ActionButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box bgcolor="#202020" color="#fff" p={3} borderRadius={2} boxShadow={3}>
                            <Typography variant="h6">My Referrals ({referrals.length})</Typography>
                            <List>
                                {currentReferrals.map((referral, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem button onClick={() => handleExpandClick(index)}>
                                            <ListItemText primary={referral.name} secondary={`Status: ${referral.status}`} />
                                            {expanded[index] ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                            <Box ml={3}>
                                                <Typography variant="body2">Email: {referral.email}</Typography>
                                                <Typography variant="body2">Phone: {referral.phone}</Typography>
                                                {renderTrackingUI(referral.stages)}
                                            </Box>
                                        </Collapse>
                                    </React.Fragment>
                                ))}
                            </List>
                            <Box display="flex" justifyContent="center" mt={2}>
                                <Pagination
                                    count={Math.ceil(referrals.length / referralsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box bgcolor="#202020" color="#fff" p={3} borderRadius={2} boxShadow={3} onClick={() => setExpanded((prev) => ({ ...prev, personalInfo: !prev.personalInfo }))} style={{ cursor: 'pointer' }}>
                            <Typography variant="h6">
                                Edit Personal Info
                                {expanded.personalInfo ? <ExpandLess /> : <ExpandMore />}
                            </Typography>
                            <Collapse in={expanded.personalInfo} timeout="auto" unmountOnExit>
                                <TextField
                                    label="First Name"
                                    name="firstName"
                                    value={personalInfo.firstName}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="filled"
                                    InputLabelProps={{ style: { color: '#fff' } }}
                                    InputProps={{ style: { color: '#fff' } }}
                                />
                                <TextField
                                    label="Last Name"
                                    name="lastName"
                                    value={personalInfo.lastName}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="filled"
                                    InputLabelProps={{ style: { color: '#fff' } }}
                                    InputProps={{ style: { color: '#fff' } }}
                                />
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={personalInfo.email}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="filled"
                                    InputLabelProps={{ style: { color: '#fff' } }}
                                    InputProps={{ style: { color: '#fff' } }}
                                />
                                <TextField
                                    label="Phone"
                                    name="phone"
                                    value={personalInfo.phone}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="filled"
                                    InputLabelProps={{ style: { color: '#fff' } }}
                                    InputProps={{ style: { color: '#fff' } }}
                                />
                                <TextField
                                    label="Street"
                                    name="street"
                                    value={personalInfo.street}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="filled"
                                    InputLabelProps={{ style: { color: '#fff' } }}
                                    InputProps={{ style: { color: '#fff' } }}
                                />
                                <TextField
                                    label="City"
                                    name="city"
                                    value={personalInfo.city}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="filled"
                                    InputLabelProps={{ style: { color: '#fff' } }}
                                    InputProps={{ style: { color: '#fff' } }}
                                />
                                <TextField
                                    label="State"
                                    name="state"
                                    value={personalInfo.state}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="filled"
                                    InputLabelProps={{ style: { color: '#fff' } }}
                                    InputProps={{ style: { color: '#fff' } }}
                                />
                                <TextField
                                    label="Zip Code"
                                    name="zipCode"
                                    value={personalInfo.zipCode}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="filled"
                                    InputLabelProps={{ style: { color: '#fff' } }}
                                    InputProps={{ style: { color: '#fff' } }}
                                />
                                <ActionButton onClick={handleSavePersonalInfo}>Save</ActionButton>
                            </Collapse>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box bgcolor="#202020" color="#fff" p={3} borderRadius={2} boxShadow={3}>
                            <Typography variant="h6">Link Your Bank Account</Typography>
                            <ActionButton fullWidth>Link with Stripe</ActionButton>
                            <ActionButton fullWidth>Link with Plaid</ActionButton>
                            <ActionButton fullWidth>Link with PayPal</ActionButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box bgcolor="#202020" color="#fff" p={3} borderRadius={2} boxShadow={3}>
                            <Typography variant="h6">Affiliate Commissions</Typography>
                            <List>
                                <Box display="flex" justifyContent="space-between" py={1} borderBottom="1px solid #444">
                                    <Typography variant="body2">Date</Typography>
                                    <Typography variant="body2">Amount</Typography>
                                    <Typography variant="body2">Reference</Typography>
                                </Box>
                                {payouts.map((payout, index) => (
                                    <Box key={index} display="flex" justifyContent="space-between" py={1} borderBottom="1px solid #444">
                                        <Typography variant="body2">{payout.date}</Typography>
                                        <Typography variant="body2">${payout.amount}</Typography>
                                        <Link href={`#`} target="_blank" variant="body2" style={{ color: '#12c099' }}>
                                            {payout.reference}
                                        </Link>
                                    </Box>
                                ))}
                            </List>
                        </Box>
                    </Grid>
                </Grid>

                <Dialog open={withdrawDialogOpen} onClose={handleCloseWithdrawDialog} PaperProps={{ style: { backgroundColor: '#1c1c1c' } }}>
                    <DialogTitle style={{ color: '#fff' }}>Withdraw Earnings</DialogTitle>
                    <DialogContent>
                        <Typography style={{ color: '#fff' }}>Your earnings will be transferred to your linked payment account (bank or PayPal).</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseWithdrawDialog} style={{ color: '#12c099' }}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmWithdraw} style={{ color: '#12c099' }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>

            <Footer>
                <FooterLink href="/faq">FAQ</FooterLink>
                <FooterLink href="/company-policy">Company Policy</FooterLink>
                <FooterLink href="/privacy">Privacy Policy</FooterLink>
                <FooterLink href="/terms-of-use">Terms of Use</FooterLink>
                <FooterLink href="/help-center">Help Center</FooterLink>
            </Footer>
        </Root>
    );
};

export default AffiliateDashboard;
