import React from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Logo = styled('img')({
    height: 'auto',
    width: 'auto',
    maxWidth: '100px',
    minWidth: '50px',
    cursor: 'pointer',
});

const Spacer = styled(Box)({
    flexGrow: 1,
});

const NavigationBar = ({ isLoggedIn, handleLogout }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleLogin = () => {
        navigate('/login');
    };

    const handleDashboard = () => {
        navigate('/affiliate-dashboard');
    };

    const handleLogoutClick = () => {
        handleLogout();
    };

    const handleLogoClick = () => {
        navigate('/choose-plan');
    };

    return (
        <AppBar position="fixed" style={{ backgroundColor: 'rgba(20, 20, 20, 0.8)' }}>
            <Toolbar>
                <Logo
                    src="https://marsreel.com/wp-content/themes/marsreel/images/shared/marsreel-logo.png"
                    alt="MarsReel"
                    onClick={handleLogoClick}
                />
                <Spacer />
                {isLoggedIn ? (
                    <>
                        <Button color="inherit" onClick={handleDashboard}>
                            Dashboard
                        </Button>
                        <Button color="inherit" onClick={handleLogoutClick}>
                            Logout
                        </Button>
                    </>
                ) : (
                    <Button color="inherit" onClick={handleLogin}>
                        Login
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
