import React, { useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import CreateAccount from './CreateAccount';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import FAQ from './FAQ';
import HelpCenter from './HelpCenter';
import TermsOfUse from './TermsOfUse';
import Privacy from './Privacy';
import CompanyPolicy from './CompanyPolicy';
import AffiliateDashboard from './AffiliateDashboard';
import Home from './Home';
import Questionnaire from './Questionnaire'; // Import the Questionnaire component
import { useAuth } from './context/AuthContext';

function App() {
  const { currentUser, logout } = useAuth();
  const [cityName, setCityName] = useState('');
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleLogin = () => {
    navigate('/affiliate-dashboard');  // Redirect to the affiliate dashboard
  };

  return (
      <div style={{ height: '100%' }}>
        <NavigationBar isLoggedIn={!!currentUser} handleLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-account" element={<CreateAccount handleLogin={handleLogin} />} />
          <Route path="/login" element={<Login handleLogin={handleLogin} />} />
          <Route path="/forgot-password" element={<ForgotPassword handleLogin={handleLogin} />} />
          <Route path="/affiliate-dashboard" element={<AffiliateDashboard />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/company-policy" element={<CompanyPolicy />} />
          <Route path="/questionnaire" element={<Questionnaire />} /> {/* Add the Questionnaire route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
  );
}

export default App;
