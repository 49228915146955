import React, { useState } from 'react';
import { Container, TextField, Button, Typography, AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
});

const StyledAppBar = styled(AppBar)({
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    borderBottom: '1px solid #e6e6e6',
    zIndex: 10,
    position: 'fixed',
    top: 0,
    width: '100%',
});

const Logo = styled('img')({
    flexGrow: 1,
    height: 'auto',
    width: '20%',
    maxWidth: '100px',
    minWidth: '50px',
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem',
});

const FormContainer = styled(Container)({
    backgroundColor: 'rgba(51, 51, 51, 0.8)',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    marginTop: '2rem',
    zIndex: 2,
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#000', // Black text color inside the button
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '1rem',
});

const Footer = styled('footer')({
    backgroundColor: '#000',
    padding: '20px 0',
});

const FooterContainer = styled('div')({
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',

    '@media (min-width: 768px)': {
        gap: '15px',
    },
});

const FooterLink = styled('a')({
    color: '#12c099',
    textDecoration: 'none',
    fontSize: '0.8em',

    '&:hover': {
        color: '#0f9f85',
    },

    '@media (min-width: 768px)': {
        fontSize: '0.9em',
    },
});

const CreateAccount = () => {
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        console.log({
            name,
            street,
            city,
            state,
            zipCode,
            email,
            phoneNumber,
        });
        navigate('/questionnaire');
    };

    return (
        <Root>
            <StyledAppBar position="fixed">
                <Toolbar>
                    <Logo src="https://marsreel.com/wp-content/themes/marsreel/images/shared/marsreel-logo.png" alt="MarsReel" />
                </Toolbar>
            </StyledAppBar>

            <StyledContainer maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    Create an Account
                </Typography>
                <FormContainer>
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label="Street"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label="Zip Code"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <ActionButton fullWidth variant="contained" onClick={handleSubmit}>
                        Submit
                    </ActionButton>
                </FormContainer>
            </StyledContainer>
            <Footer>
                <FooterContainer>
                    <FooterLink href="/faq">FAQ</FooterLink>
                    <FooterLink href="/company-policy">Company Policy</FooterLink>
                    <FooterLink href="/privacy">Privacy Policy</FooterLink>
                    <FooterLink href="/terms-of-use">Terms of Use</FooterLink>
                    <FooterLink href="/help-center">Help Center</FooterLink>
                </FooterContainer>
            </Footer>
        </Root>
    );
};

export default CreateAccount;
