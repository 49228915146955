import React from 'react';
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem', // Adjust this value to ensure content is not under the navigation bar
    paddingBottom: '2rem',
    minHeight: '100vh',
    backgroundColor: '#000', // Set background color to black
    color: '#fff', // Set text color to white
});

const StyledCard = styled(Card)({
    marginBottom: '1rem',
    height: '100%',
    width: '100%',
    backgroundColor: '#333', // Darker background for cards
    color: '#fff', // Set text color to white
});

const Footer = styled('footer')({
    backgroundColor: '#000',
    padding: '20px 0',
});

const FooterContainer = styled('div')({
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',

    '@media (min-width: 768px)': {
        gap: '15px',
    },
});

const FooterLink = styled('a')({
    color: '#12c099',
    textDecoration: 'none',
    fontSize: '0.8em',

    '&:hover': {
        color: '#0f9f85',
    },

    '@media (min-width: 768px)': {
        fontSize: '0.9em',
    },
});

const HelpCenter = () => {
    return (
        <>
            <StyledContainer maxWidth="lg">
                <Typography variant="h4" gutterBottom>
                    Help Center
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6">Account Management</Typography>
                                <Typography variant="body2">
                                    Learn how to update your profile, change your password, and manage other account settings.
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6">Payment Issues</Typography>
                                <Typography variant="body2">
                                    Get help with updating payment methods, understanding billing cycles, and resolving payment issues.
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6">Technical Support</Typography>
                                <Typography variant="body2">
                                    Tips for troubleshooting common technical issues and how to contact our technical support team for further assistance.
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6">Subscription Plans</Typography>
                                <Typography variant="body2">
                                    Information about our subscription plans, including the benefits and how to switch between plans.
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6">Using Credits</Typography>
                                <Typography variant="body2">
                                    Learn how to use credits for accessing various activities and events.
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6">Contact Us</Typography>
                                <Typography variant="body2">
                                    Find out how to contact our support team for additional assistance.
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                </Grid>
            </StyledContainer>
            <Footer>
                <FooterContainer>
                    <FooterLink href="/faq">FAQ</FooterLink>
                    <FooterLink href="/company-policy">Company Policy</FooterLink>
                    <FooterLink href="/privacy">Privacy Policy</FooterLink>
                    <FooterLink href="/terms-of-use">Terms of Use</FooterLink>
                    <FooterLink href="/help-center">Help Center</FooterLink>
                </FooterContainer>
            </Footer>
        </>
    );
};

export default HelpCenter;
