import React, { useState } from 'react';
import { Container, Typography, Button, TextField, FormControlLabel, Checkbox, AppBar, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000', // Netflix black background
});

const StyledAppBar = styled(AppBar)({
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    borderBottom: '1px solid #e6e6e6',
    zIndex: 10,
    position: 'fixed',
    top: 0,
    width: '100%',
});

const Logo = styled('img')({
    flexGrow: 1,
    height: 'auto',
    width: '20%',
    maxWidth: '100px',
    minWidth: '50px',
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem',
});

const FormContainer = styled(Container)({
    backgroundColor: 'rgba(51, 51, 51, 0.8)',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    marginTop: '2rem',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    maxWidth: '600px',
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#000', // Black text color inside the button
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '1rem',
    alignSelf: 'stretch',
});

const TextFieldStyled = styled(TextField)({
    width: '100%',
    '& .MuiInputLabel-root': {
        color: '#fff',
    },
    '& .MuiInputBase-root': {
        color: '#fff',
    },
    '& .MuiFilledInput-underline:before': {
        borderBottomColor: '#fff',
    },
    '& .MuiFilledInput-underline:after': {
        borderBottomColor: '#12c099',
    },
    '& .MuiFilledInput-root': {
        backgroundColor: 'rgba(51, 51, 51, 0.8)',
    },
});

const Footer = styled('footer')({
    backgroundColor: '#000',
    padding: '20px 0',
    marginTop: 'auto',
});

const FooterContainer = styled('div')({
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',

    '@media (min-width: 768px)': {
        gap: '15px',
    },
});

const FooterLink = styled('a')({
    color: '#12c099',
    textDecoration: 'none',
    fontSize: '0.8em',

    '&:hover': {
        color: '#0f9f85',
    },

    '@media (min-width: 768px)': {
        fontSize: '0.9em',
    },
});

const Questionnaire = () => {
    const [responses, setResponses] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (question, value) => {
        setResponses((prev) => ({ ...prev, [question]: value }));
    };

    const handleSubmit = () => {
        console.log(responses);
        setSubmitted(true);
    };

    return (
        <Root>
            <StyledAppBar position="fixed">
                <Toolbar>
                    <Logo src="https://marsreel.com/wp-content/themes/marsreel/images/shared/marsreel-logo.png" alt="MarsReel" />
                </Toolbar>
            </StyledAppBar>
            <StyledContainer maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    Ambassador Program Questionnaire
                </Typography>
                {submitted ? (
                    <Typography variant="h5" style={{ color: '#12c099' }}>
                        Thank you! We will be in touch.
                    </Typography>
                ) : (
                    <FormContainer>
                        <TextFieldStyled
                            label="Why do you want to be an ambassador for Mars Reel Academy?"
                            value={responses.whyAmbassador || ''}
                            onChange={(e) => handleChange('whyAmbassador', e.target.value)}
                            margin="dense"
                            variant="filled"
                            multiline
                            rows={4}
                        />
                        <TextFieldStyled
                            label="Describe your experience with sports and training."
                            value={responses.sportsExperience || ''}
                            onChange={(e) => handleChange('sportsExperience', e.target.value)}
                            margin="dense"
                            variant="filled"
                            multiline
                            rows={4}
                        />
                        <TextFieldStyled
                            label="How would you promote Mars Reel Academy to potential members?"
                            value={responses.promotionStrategy || ''}
                            onChange={(e) => handleChange('promotionStrategy', e.target.value)}
                            margin="dense"
                            variant="filled"
                            multiline
                            rows={4}
                        />
                        <TextFieldStyled
                            label="What makes you a good fit for this ambassador program?"
                            value={responses.goodFit || ''}
                            onChange={(e) => handleChange('goodFit', e.target.value)}
                            margin="dense"
                            variant="filled"
                            multiline
                            rows={4}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={responses.agreeTerms || false}
                                    onChange={(e) => handleChange('agreeTerms', e.target.checked)}
                                    style={{ color: '#12c099' }}
                                />
                            }
                            label={<Typography style={{ color: '#fff' }}>I agree to the terms and conditions</Typography>}
                        />
                        <ActionButton variant="contained" onClick={handleSubmit}>
                            Submit
                        </ActionButton>
                    </FormContainer>
                )}
            </StyledContainer>
            <Footer>
                <FooterContainer>
                    <FooterLink href="/faq">FAQ</FooterLink>
                    <FooterLink href="/company-policy">Company Policy</FooterLink>
                    <FooterLink href="/privacy">Privacy Policy</FooterLink>
                    <FooterLink href="/terms-of-use">Terms of Use</FooterLink>
                    <FooterLink href="/help-center">Help Center</FooterLink>
                </FooterContainer>
            </Footer>
        </Root>
    );
};

export default Questionnaire;
