import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
    position: relative;
    overflow: hidden;
    color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #000;
`;

const HeroSection = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #000;
    color: #fff;
    padding: 20px;
    background-image: url('https://path-to-your-background-image.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    box-sizing: border-box;
`;

const HeroContent = styled.div`
    max-width: 90%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    width: 100%;

    @media (min-width: 768px) {
        max-width: 700px;
        padding: 40px;
    }
`;

const HeroTitle = styled.h1`
    font-size: 2em;
    margin-bottom: 15px;
    font-weight: bold;
    line-height: 1.2;

    @media (min-width: 768px) {
        font-size: 3em;
    }
`;

const HeroText = styled.p`
    font-size: 1em;
    margin-bottom: 20px;
    line-height: 1.5;
    text-align: left;

    @media (min-width: 768px) {
        font-size: 1.2em;
        margin-bottom: 30px;
    }
`;

const Button = styled.a`
    display: inline-block;
    padding: 10px 20px;
    background-color: #12c099;
    color: #000000;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1em;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0f9f85;
    }

    @media (min-width: 768px) {
        padding: 12px 25px;
        font-size: 1.2em;
    }
`;

const Footer = styled.footer`
    background-color: #000;
    padding: 20px 0;
`;

const FooterContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    @media (min-width: 768px) {
        gap: 15px;
    }
`;

const FooterLink = styled.a`
    color: #12c099;
    text-decoration: none;
    font-size: 0.8em;

    &:hover {
        color: #0f9f85;
    }

    @media (min-width: 768px) {
        font-size: 0.9em;
    }
`;

const Home = () => {
    return (
        <Root>
            <HeroSection>
                <HeroContent>
                    <HeroTitle>Become a Mars Reel Academy Ambassador</HeroTitle>
                    <HeroText>
                        Join an exclusive community of leaders dedicated to empowering the next generation. Our Ambassador Program offers the unique opportunity to earn affiliate commissions while promoting our mission. Applications are accepted on a rolling basis and are by invitation only.
                    </HeroText>
                    <Button href="/create-account">Apply Now</Button>
                </HeroContent>
            </HeroSection>
            <Footer>
                <FooterContainer>
                    <FooterLink href="/faq">FAQ</FooterLink>
                    <FooterLink href="/company-policy">Company Policy</FooterLink>
                    <FooterLink href="/privacy">Privacy Policy</FooterLink>
                    <FooterLink href="/terms-of-use">Terms of Use</FooterLink>
                    <FooterLink href="/help-center">Help Center</FooterLink>
                </FooterContainer>
            </Footer>
        </Root>
    );
};

export default Home;
