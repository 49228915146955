import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem', // Adjust this value to ensure content is not under the navigation bar
    paddingBottom: '2rem',
    minHeight: '100vh',
    backgroundColor: '#000', // Set background color to black
    color: '#fff', // Set text color to white
});

const Section = styled('div')({
    marginBottom: '1.5rem',
});

const Footer = styled('footer')({
    backgroundColor: '#000',
    padding: '20px 0',
});

const FooterContainer = styled('div')({
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',

    '@media (min-width: 768px)': {
        gap: '15px',
    },
});

const FooterLink = styled('a')({
    color: '#12c099',
    textDecoration: 'none',
    fontSize: '0.8em',

    '&:hover': {
        color: '#0f9f85',
    },

    '@media (min-width: 768px)': {
        fontSize: '0.9em',
    },
});

const TermsOfUse = () => {
    return (
        <>
            <StyledContainer maxWidth="lg">
                <Typography variant="h4" gutterBottom>
                    Terms of Use
                </Typography>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 1: Introduction
                    </Typography>
                    <Typography variant="body1">
                        Welcome to Mars Reel Media Corp. These terms and conditions outline the rules and regulations for the use of our website and services.
                    </Typography>
                </Section>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 2: Intellectual Property Rights
                    </Typography>
                    <Typography variant="body1">
                        Unless otherwise stated, Mars Reel Media Corp and/or its licensors own the intellectual property rights for all material on Mars Reel. All intellectual property rights are reserved.
                    </Typography>
                </Section>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 3: Restrictions
                    </Typography>
                    <Typography variant="body1">
                        You are specifically restricted from all of the following: publishing any website material in any other media, selling, sublicensing, and/or otherwise commercializing any website material, publicly performing and/or showing any website material.
                    </Typography>
                </Section>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 4: User Content
                    </Typography>
                    <Typography variant="body1">
                        In these website standard terms and conditions, “user content” shall mean any audio, video, text, images, or other material you choose to display on this website. By displaying user content, you grant Mars Reel Media Corp a non-exclusive, worldwide, irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any media.
                    </Typography>
                </Section>
                <Section>
                    <Typography variant="h6" gutterBottom>
                        Section 5: Limitation of Liability
                    </Typography>
                    <Typography variant="body1">
                        In no event shall Mars Reel Media Corp, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website, whether such liability is under contract. Mars Reel Media Corp, including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.
                    </Typography>
                </Section>
                {/* Add more sections as needed */}
            </StyledContainer>
            <Footer>
                <FooterContainer>
                    <FooterLink href="/faq">FAQ</FooterLink>
                    <FooterLink href="/company-policy">Company Policy</FooterLink>
                    <FooterLink href="/privacy">Privacy Policy</FooterLink>
                    <FooterLink href="/terms-of-use">Terms of Use</FooterLink>
                    <FooterLink href="/help-center">Help Center</FooterLink>
                </FooterContainer>
            </Footer>
        </>
    );
};

export default TermsOfUse;
